import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import csharp from "../images/languageLogos/csharp.png"
import github from "../images/languageLogos/github.png"
import javascript from "../images/languageLogos/javascript.png"
import powershell from "../images/languageLogos/powershell.png"
import python from "../images/languageLogos/python.png"
import ruby from "../images/languageLogos/ruby.png"
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  logo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5px",
  },
}));

const DevLanguageLogo = ({language, useLink=false}) => {
  const classes = useStyles();

  let image;
  switch(language){
    case "csharp":
      image = <img alt={language} src={csharp}/>;
      break;
    case "github":
      image = <img alt={language} src={github}/>;
      break;
    case "javascript":
      image = <img alt={language} src={javascript}/>;
      break;
    case "powershell":
      image = <img alt={language} src={powershell}/>;
      break;
    case "python":
      image = <img alt={language} src={python}/>;
      break;
    case "ruby":
      image = <img alt={language} src={ruby}/>;
      break;
    default:
      break;
  }

  return(
    <Box className={classes.logo}>
      {
        useLink
        ? <Link
            href={"https://github.com/kks110"}
          >
            {image}
          </Link>
        : image
      }
    </Box>
  )
};

DevLanguageLogo.propTypes = {
  useLink: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

export default DevLanguageLogo;