import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  devBoxes: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: "2px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
    width: "1",
  },
  textLayout: {
    marginTop: 12,
    textAlign: "center",
    whiteSpace: "pre-line",
  },
}));

const DevDetails = ({languageClass, githubLink, title, body}) => {
  const classes = useStyles();
  return(
    <Box className={`${languageClass} ${classes.devBoxes}`}>
      <Typography className={classes.textLayout} variant="h5">
        <Link
          color="inherit"
          href={githubLink}
        >
          {title}
        </Link>
      </Typography>
      <Typography className={classes.textLayout} variant="body1" component="p">
        {body.join('\n')}
      </Typography>
    </Box>
  )
};

DevDetails.propTypes = {
  languageClass: PropTypes.array.isRequired,
  githubLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
};

export default DevDetails;