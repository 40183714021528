import React from 'react';
import Box from '@material-ui/core/Box';
import Layout from "../components/Layout";
import SiteHelmet from "../components/SiteHelmet";
import {makeStyles} from "@material-ui/core";
import DevLanguageLogo from "../components/DevLanguageLogo";
import DevDetails from "../components/DevDetails";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: "20px",
  },
  csharp: {
    borderColor: theme.palette.languages.csharp.primary,
  },
  csharpLight: {
    borderColor: theme.palette.languages.csharp.light,
  },
  powershell: {
    borderColor: theme.palette.languages.powershell.primary,
  },
  powershellLight: {
    borderColor: theme.palette.languages.powershell.light,
  },
  python: {
    borderColor: theme.palette.languages.python.primary,
  },
  pythonLight: {
    borderColor: theme.palette.languages.python.light,
  },
  ruby: {
    borderColor: theme.palette.languages.ruby.primary,
  },
  rubyLight: {
    borderColor: theme.palette.languages.ruby.light,
  },
  javascript: {
    borderColor: theme.palette.languages.javascript.primary,
  },
  javascriptLight: {
    borderColor: theme.palette.languages.javascript.light,
  },
}));

const Dev = () => {
  const classes = useStyles();
  return (
    <Layout>
      <SiteHelmet title="Dev"/>
      <Box className={classes.root}>
        <DevLanguageLogo language={"github"} useLink={true}/>
          <DevLanguageLogo language={"csharp"}/>
          <DevDetails
            githubLink={"https://github.com/kks110/c-sharp-tic-tac-toe"}
            body={
              [
                "First attempt to make something in C#.",
                "Just a simple noughts and crosses game."
              ]
            }
            languageClass={classes.csharp}
            title={"Noughts and crosses written in C#"}
          />

          <DevLanguageLogo language={"python"}/>
          <DevDetails
            githubLink={"https://github.com/kks110/python-bat-out-of-hell-game"}
            body={
              [
                "Bat out of Hell is a bullet dodging game made using Python / Pygame.",
                "This is my first attempt at making a game using pygame. I started by following a tutorial, but have added more features.",
                "See the README for a full list of added features."
              ]
            }
            languageClass={classes.python}
            title={"Bat out of Hell - A game made using pygame"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/python-lol-discord-bot"}
            body={
              [
                "A discord bot that allows you to register your league of legends summoner name",
                "You can then run a command to return the ranks and win rate of al registered summoners."
              ]
            }
            languageClass={classes.python}
            title={"League of Legends Discord Bot"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/python-lol-random-champ-selector"}
            body={
              [
                "After deciding a run a 1v1 League of Legends tournament. It was decided that we wanted the champ selection to be random",
                "This tool talks to the Riot Games API to get a list of champions and then random selects some for each player.",
                "It also implements a ban list if there are some champs that your group doesn't want to see anyone play."
              ]
            }
            languageClass={classes.python}
            title={"A Random League of Legends Champion selector"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/python-lol-champ-stats"}
            body={
              [
                "This is a python script which will talk to the Riot Static game data API.",
                "It will pull all of the stats (eg, health, health regen, AD, ect..) for all of the champions in the game.",
                "It will then save the data to a CSV file.",
                "The static data is manually updated by the Riot team.",
                "When a patch comes out, it won't always be updated on the same day."
              ]
            }
            languageClass={classes.python}
            title={"A script to download League of Legends champion stats"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/python-mtg-setlist-downloader"}
            body={
              [
                "Using the Scryfall API, this script will download a list of cards from a MtG set and save it to a CSV file.",
                "It will also download tokens and promo cards for the set.",
                "It returns card number, card name, colour and rarity.",
                "Also gives the option to list the pre-release promos",
                "Handy for generating a list for collections (that's initially why I made it)."
              ]
            }
            languageClass={classes.python}
            title={"A script to download lists of Magic the Gathering sets"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/python-mtg-card-type-puller"}
            body={
              [
                "This will ask what type of card you want to download a list of, eg- turtles, lands, merfolk, ect.",
                "The lists can be quite large, and it can take a while to download, so be patient.",
                "It will save it to a CSV file. The data it pulls includes card name, card colour, rarity, set name and set date."
              ]
            }
            languageClass={classes.python}
            title={"A script to download a list of MtG cards which are a certain type"}
          />

          <DevLanguageLogo language={"ruby"}/>
          <DevDetails
              githubLink={"https://github.com/kks110/starcall"}
              body={
                [
                  "A ruby Gem to assist with calls to Riot Games API",
                ]
              }
              languageClass={classes.ruby}
              title={"Starcall"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/Ruby-Eos-game"}
            body={
              [
                "I wanted to test my Ruby skills, so I decided to try and make a game.",
                "It's a choose your own adventure style game. I've only made the first level so far.",
                "Needs some refactoring",
              ]
            }
            languageClass={classes.ruby}
            title={"A choose your own adventure game made in Ruby"}
          />

          <DevDetails
            githubLink={"https://github.com/kks110/ruby-roman-numerals-converter"}
            body={
              [
                "Can convert Roman numbers to Arabic numbers and vice versa",
              ]
            }
            languageClass={classes.ruby}
            title={"Roman Numerals Converter"}
          />

          <DevLanguageLogo language={"javascript"}/>
          <DevDetails
            githubLink={"https://www.kks110.co.uk"}
            body={
              [
                "This site is built with React and Gatsby"
              ]
            }
            languageClass={classes.javascript}
            title={"The kks110 website"}
          />

          <DevLanguageLogo language={"powershell"}/>
          <DevDetails
            githubLink={"https://github.com/kks110/powershell-ping-check-with-time-stamp"}
            body={
              [
                "There are 2 scripts. One will keep pinging until you stop it whereas the other will ping for as long as you tell it to.",
                "Handy for troubleshooting network problems. Timestamps are useful if you can't keep an eye on it.",
                "Overnight for example."
              ]
            }
            languageClass={classes.powershell}
            title={"A script to ping and save to a timestamped file"}
          />
      </Box>
    </Layout>
  );
};

export default Dev;